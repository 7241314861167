import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getCurrentRichContent } from "../helpers/richContentHelpers";

const quantPageTplData = (id) => {
  const data = useStaticQuery(
    graphql`
      {
        allWpPage {
          edges {
            node {
              id
              template {
                ... on WpTemplate_Quant {
                  templateName
                  quantRichContent {
                    # SECTION: Hero
                    hero {
                      header
                      text
                      buttons {
                        button {
                          variant
                          link {
                            target
                            title
                            url
                          }
                        }
                      }
                      logo {
                        localFile {
                          childSvg {
                            content {
                              data
                            }
                          }
                        }
                      }
                      image {
                        altText
                        localFile {
                          extension
                          childImageSharp {
                            gatsbyImageData(
                              width: 1800
                              placeholder: BLURRED
                              quality: 100
                            )
                          }

                          childSvg {
                            content {
                              data
                            }
                          }
                        }
                      }
                    }

                    # SECTION: Our Numbers
                    ourNumbers {
                      items {
                        number
                        text
                      }
                      image {
                        ...wpImageFluid
                      }
                    }

                    # SECTION: Quant Trading Lifestyle
                    quantTradingLifestyle {
                      tabsSection {
                        header
                        text
                        slug
                        tabs {
                          label
                          fieldGroupName
                          content {
                            ... on WpTemplate_Quant_Quantrichcontent_QuantTradingLifestyle_TabsSection_tabs_Content_Accordion {
                              fieldGroupName
                              header
                              content {
                                ... on WpTemplate_Quant_Quantrichcontent_QuantTradingLifestyle_TabsSection_tabs_Content_Accordion_Content_Text {
                                  fieldGroupName
                                  header
                                  text
                                  link {
                                    target
                                    title
                                    url
                                  }
                                }
                                ... on WpTemplate_Quant_Quantrichcontent_QuantTradingLifestyle_TabsSection_tabs_Content_Accordion_Content_List {
                                  fieldGroupName
                                  header
                                  items {
                                    fieldGroupName
                                    text
                                  }
                                  textAbove
                                  textBelow
                                }
                              }
                            }
                            ... on WpTemplate_Quant_Quantrichcontent_QuantTradingLifestyle_TabsSection_tabs_Content_List {
                              fieldGroupName
                              header
                              textAbove
                              textBelow
                              items {
                                fieldGroupName
                                text
                              }
                            }
                            ... on WpTemplate_Quant_Quantrichcontent_QuantTradingLifestyle_TabsSection_tabs_Content_Text {
                              fieldGroupName
                              header
                              text
                              link {
                                target
                                title
                                url
                              }
                            }
                          }
                        }
                      }
                    }

                    # SECTION: Product Mockup Image
                    productMockupImage {
                      imageSection {
                        text
                        title
                        slug
                        variant
                        image {
                          ...wpImageFluid
                        }
                      }
                    }

                    # SECTION: Features
                    features {
                      header
                      text
                      button {
                        target
                        title
                        url
                      }
                      topList {
                        header
                        text
                      }
                      iconsList {
                        header
                        text
                        icon {
                          ...wpImage
                        }
                      }
                    }

                    #
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  return getCurrentRichContent(data, id, "quantRichContent");
};

export { quantPageTplData };
